import { useLocation, useNavigate } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/common/buttons/Button';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { isLocationHomepage } from '~/components/utils/location';
import { pathLocaleReplace } from '~/components/utils/text';
import type { SupportedLanguage } from '~/config/i18n';
import { ACTIVE_LANGUAGES, useLanguageParam } from '~/config/i18n';
import type { ButtonSizeType, ButtonVariantType } from '~/types/buttons';

export const LanguageSwitcher = ({
  variant = 'primaryGhost',
  showLabel = false,
  buttonSize = 'lg',
}: {
  variant: ButtonVariantType;
  showLabel?: boolean;
  buttonSize?: ButtonSizeType;
}) => {
  const { t } = useTranslation();
  const lang = useLanguageParam();
  const [languageCode, setLanguageCode] = useState(lang);
  const [language, setLanguage] = useState(lang === 'en' ? 'english' : 'french');
  const { i18n } = useTranslation();
  const location = useLocation();
  const obfuscated = !isLocationHomepage(location);

  const languageNames = { en: 'english', fr: 'french' };

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const onChangeLang = async (langCode: SupportedLanguage) => {
    await i18n.changeLanguage(langCode);

    navigate(getPathForLang(langCode));
  };

  const getPathForLang = (langCode: SupportedLanguage) => {
    return `${pathLocaleReplace(pathname, lang, langCode)}${search}`;
  };

  useEffect(() => {
    setLanguageCode(lang);
    setLanguage(lang === 'en' ? 'english' : 'french');
  }, [lang]);

  return (
    <form>
      <div className="dropdown py-md-0">
        <Button
          variant={variant}
          extraClasses={`${showLabel ? '' : 'p-0'}`}
          size={buttonSize}
          isIconButton={!showLabel}
          name="Switch language"
          dataAttributes={{ 'data-bs-toggle': 'dropdown' }}
          ariaAttributes={{ 'aria-expanded': 'false', 'aria-label': 'Switch language' }}
        >
          <SVGIcon
            iconType={'globe'}
            className="m-0"
          />
          {showLabel && <span className="ms-2 text-2">{t(`header.languageSwitcher.dropdown.${language}`)}</span>}
        </Button>
        <ul className="dropdown-menu dropdown-menu-end mt-2">
          <span className="dropdown-header fo-display-5 color-primary -medium">
            {t('header.languageSwitcher.dropdown.title')}
          </span>

          <div className="dropdown-divider border-light m-0" />
          {ACTIVE_LANGUAGES.map((langCode) => (
            <li
              className="dropdown-item cursor-pointer"
              onClick={(e) => {
                onChangeLang(langCode);
                e.preventDefault();
              }}
              key={langCode}
            >
              {obfuscated ? (
                <div className="form-check d-flex align-items-center">
                  <LanguageItem
                    langCode={langCode}
                    langName={t(`header.languageSwitcher.dropdown.${languageNames[langCode]}`)}
                    currentLang={languageCode}
                  />
                </div>
              ) : (
                <a
                  className="form-check d-flex align-items-center"
                  href={getPathForLang(langCode)}
                >
                  <LanguageItem
                    langCode={langCode}
                    langName={t(`header.languageSwitcher.dropdown.${languageNames[langCode]}`)}
                    currentLang={languageCode}
                  />
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    </form>
  );
};

const LanguageItem = ({
  langCode,
  langName,
  currentLang,
}: {
  langCode: SupportedLanguage;
  langName: string;
  currentLang: SupportedLanguage;
}) => {
  return (
    <>
      <input
        className="form-check-input cursor-pointer"
        type="radio"
        name="flexRadioDefault"
        id={`flexRadioDefault${langCode}`}
        value={langCode}
        checked={currentLang === langCode}
        readOnly
      />
      <label
        className="ms-3 text-1 -medium form-check-label cursor-pointer"
        htmlFor={`flexRadioDefault${langCode}`}
      >
        {langName}
      </label>
    </>
  );
};
