// @ts-ignore
export function isLocationHomepage(location: Location<any>) {
  return location.pathname === '/fr' || location.pathname === '/en';
}

export function getRedirectUrl(url: string, locale = '/fr', useForwarder = false) {
  const hasLocale = url.includes('/fr') || url.includes('/en');

  const redirectUrl = url.startsWith('/') ? url : `/${url}`;

  const redirectTo = hasLocale ? redirectUrl : `/${locale}${redirectUrl}`;

  return useForwarder ? `/forwarder?forwardTo=${redirectTo}` : redirectTo;
}
