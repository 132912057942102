import { DEFAULT_LANGUAGE } from '~/config/i18n';

export const getNumberOrdinal = (number: number, lang: string | undefined): string => {
  lang = lang || DEFAULT_LANGUAGE;
  const ordinalMapEn = ['th', 'st', 'nd', 'rd'];
  const ordinalMapFr = ['ème', 'er', 'ème', 'ème'];

  let ordinal = '';
  if (number % 100 >= 11 && number % 100 <= 13) {
    ordinal = ordinalMapEn[0];
  } else {
    ordinal = ordinalMapEn[number % 10] || ordinalMapEn[0];
  }

  if (lang === 'fr') {
    ordinal = ordinalMapFr[number === 1 ? 1 : number > 1 ? 2 : 0];
  }

  return ordinal;
};

export const currencyDisplay = (amount: number | null | string, maximumFractionDigits: number = 2) => {
  if (amount !== 0 && !amount) return '';

  try {
    const numberAmount = Number(amount);
    if (isNaN(numberAmount)) return '';

    const formattedNumber = numberAmount.toFixed(maximumFractionDigits);

    // Add thousand separators
    const parts = formattedNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return '€ ' + parts.join('.');
  } catch (error) {
    console.error('Error formatting currency:', { amount, maximumFractionDigits, error });
    return '';
  }
};

export const priceFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: '...' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const pathLocaleReplace = (path: string, currentLocale: string | undefined, newLocale: string): string => {
  const officePath = ['/offices/', '/bureaux/'];

  const [source, target] = newLocale == 'fr' ? officePath : officePath.reverse();

  return path.replace(`/${currentLocale}`, `/${newLocale}`).replace(source, target);
};

export const capitalize = (s: string) => {
  return s[0].toUpperCase() + s.slice(1);
};

export const cityArrondissementText = (city: string, zipCode: string | undefined, lang: string | undefined) => {
  if (city === 'Paris' && zipCode) {
    const zipLastTwoDigit = parseInt(zipCode) % 100;
    if (parseInt(zipCode) > 75000 && zipLastTwoDigit <= 20) {
      const suffix = getNumberOrdinal(zipLastTwoDigit, lang);
      return `${city} ${zipLastTwoDigit}${suffix}`;
    }
  } else return city;
};

const diacriticsMap: { [key: string]: string } = {
  à: 'a',
  á: 'a',
  â: 'a',
  ã: 'a',
  ä: 'a',
  ā: 'a',
  ă: 'a',
  ą: 'a',
  è: 'e',
  é: 'e',
  ê: 'e',
  ë: 'e',
  ē: 'e',
  ĕ: 'e',
  ė: 'e',
  ę: 'e',
  ì: 'i',
  í: 'i',
  î: 'i',
  ï: 'i',
  ī: 'i',
  ĩ: 'i',
  į: 'i',
  ò: 'o',
  ó: 'o',
  ô: 'o',
  õ: 'o',
  ö: 'o',
  ō: 'o',
  ŏ: 'o',
  ù: 'u',
  ú: 'u',
  û: 'u',
  ü: 'u',
  ū: 'u',
  ũ: 'u',
  ç: 'c',
  ć: 'c',
  ċ: 'c',
  ñ: 'n',
  ņ: 'n',
  ń: 'n',
  ṃ: 'm',
  ṁ: 'm',
  ṛ: 'r',
  ŗ: 'r',
  ř: 'r',
  ṡ: 's',
  ṣ: 's',
  ś: 's',
  ş: 's',
  š: 's',
  ṭ: 't',
  ț: 't',
  ŧ: 't',
  ṿ: 'v',
  ṽ: 'v',
  ẁ: 'w',
  ẃ: 'w',
  ẅ: 'w',
  ẋ: 'x',
  ỳ: 'y',
  ý: 'y',
  ÿ: 'y',
  ẓ: 'z',
  ẕ: 'z',
  ź: 'z',
  ż: 'z',
  ß: 'ss',
  '.': '-',
  "'": '-',
  ' ': '-',
};

export const hasSpecialSigns = (input: string): boolean => {
  const diacriticsRegex = /[àáâãäāăąèéêëēĕėęìíîïīĩįòóôõöōŏùúûüūũçćċñņńṃṁṛŗřṡṣśşšṭțŧṿṽẁẃẅẋỳýÿẓẕźżß.' ]/gi;
  return diacriticsRegex.test(input.toLowerCase());
};

export const removeSpecialSigns = (input: string): string => {
  const diacriticsRegex = new RegExp(Object.keys(diacriticsMap).join('|'), 'g');

  return input.toLowerCase().replace(diacriticsRegex, (match) => diacriticsMap[match] || match);
};

const isLowercased = (input: string): boolean => {
  return input == input.toLowerCase();
};

export const isStringParamURLConform = (param: string): boolean => {
  return isLowercased(param) && !hasSpecialSigns(param);
};

export const makeStringParamURLConform = (param: string): string => {
  // toLowerCase is redundant because it is present in removeSpecialSigns ATM, but that might change
  return removeSpecialSigns(param).toLowerCase();
};
